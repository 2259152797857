import logo from './logo.svg';
import './App.css';

function App() {
  return <div class="container">
  <h1>Formulário de Contato React</h1>
  <form target="_self" action="https://formsubmit.co/contato@pattyweb.com.br" method="POST">

    <div class="form-group">
      <div class="form-row">
        
        <div class="col">
          <input type="text" name="name" class="form-control" placeholder="Seu Nome" required />
        </div>
        <div class="col">
          <input type="email" name="email" class="form-control" placeholder="Seu Email" required />
        </div>
      </div>
    </div>
    <div class="form-group">
      <textarea placeholder="Mensagem" class="form-control" name="message" rows="10" required>
      </textarea>
    </div>
    <button type="submit" class="btn btn-lg btn-dark btn-block">Enviar</button>
    <input type="hidden" name="_next" value="https://formreact.pattyweb.com.br/obrigado.html"/>


  </form>
</div>;
}

export default App;
